import { px2rem } from "./functions";

const font = {
  body: "Inter, Arial, sans-serif",
  display: "InterDisplay, Arial, sans-serif",
  serif: "Suisse Works, serif",
};

const fontSize = {
  xxs: 14.4,
  xs: 12.8,
  s: 11.7647,
  m: 12.6431,
  l: 14.4,
  xl: 19.2,
  scaleMobile: Math.sqrt(1.5),
  scaleDesktop: Math.sqrt(1.75),
};

const fontWeight = {
  regular: 400,
  bold: 700,
};

const displayLetterSpacing = "-0.0175em";

// Screen size breakpoints

const breakpoint = {
  xxs: 390, // small mobile
  xs: 420, // portrait mobile
  xsRem: px2rem(420, fontSize.xs),
  s: 720, // landscape mobile
  m: 960, // portrait tablet / small desktop
  l: 1440, // landscape tablet / desktop
  lRem: px2rem(1440, fontSize.l),
  xl: 1920, // max size (puts 1 col outside i.e. content is 12 cols of 14)
  innerMax: 1920, // if used - set at a desired max width for all the content
};

const margin = {
  xxs: "0.375rem",
  xs: "0.5rem",
  s: "1rem",
  m: "1.5rem",
  l: {
    mobile: "2.5rem",
    desktop: "3rem",
  },
  xl: {
    mobile: "3rem",
    desktop: "5.5rem",
  },
  xxl: {
    mobile: "4rem",
    desktop: "7.5rem",
  },
};

const mediaQuery = {
  xxs: `@media all and (min-width: ${breakpoint.xxs}px)`,
  xs: `@media all and (min-width: ${breakpoint.xs}px)`,
  s: `@media all and (min-width: ${breakpoint.s}px)`,
  m: `@media all and (min-width: ${breakpoint.m}px)`,
  l: `@media all and (min-width: ${breakpoint.l}px)`,
  xl: `@media all and (min-width: ${breakpoint.xl}px)`,
};

// colors

const color = {
  purple: {
    light: "#F7D7FC",
    regular: "#F0AFFA",
    dark: "#884BA5",
  },
  yellow: "#FFED63",
  orange: "#FA9146",
  blue: "#91DCFA",
  red: "#C33A0E",
  backgroundRed: "rgba(195, 58, 14, 0.05)",
  grey: {
    dark: "#5B5857",
    regular: "#767472",
    medium: "#CECCC4",
    light: "#F5F3F3",
  },
  black: "#1D1B1B",
  white: "#fff",
  gradient: {
    light: {
      placeholder: `linear-gradient(
        to left,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.66),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.66),
        rgba(255, 255, 255, 0.05)
      )`,
      normal: "linear-gradient(45deg, #FA9146 20%, #F0AFFA 80%)",
      long: "linear-gradient(45deg, rgba(240,175,250,1) 0%, rgba(246,158,146,1) 41%, rgba(247,154,125,1) 58%, rgba(240,175,250,1) 100%)",
    },
  },
};

const colorsLightTheme = {
  primary: color.purple,
  secondary: {
    regular: color.yellow,
    dark: color.orange,
  },
  tertiary: {
    regular: color.blue,
  },
  passive: color.grey,
  text: {
    primary: color.black,
    secondary: color.grey.dark,
    error: color.red,
  },
  background: {
    primary: color.white,
    secondary: color.grey.light,
    error: color.backgroundRed,
  },
  border: {
    primary: color.grey.medium,
    error: color.red,
  },
  gradient: color.gradient.light,
};

const shadow = "0 0 2rem rgba(0,0,0,0.06)";

// transition settings

const transitionTime = 230;

const ease = {
  inOut: "cubic-bezier(0.445, 0.05, 0.55, 0.95)",
  out: "cubic-bezier(0.39, 0.575, 0.565, 1)",
  in: "cubic-bezier(0.47, 0, 0.745, 0.715)",
  special: "cubic-bezier(0.18, 1, 0.21, 1)",
};

const mindler2022theme = {
  font,
  fontSize,
  fontWeight,
  displayLetterSpacing,
  breakpoint,
  mediaQuery,
  margin,
  color: colorsLightTheme,
  shadow,
  transitionTime,
  ease,
};

export { mindler2022theme };
