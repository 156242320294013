import i18next, { use } from "i18next";
import { initReactI18next } from "react-i18next";
import type { Resource } from "i18next";
import {
  resources,
  SupportedLanguage,
  supportedLanguages,
} from "./i18nResources";

// check browser locale.
export const getLocale = (): SupportedLanguage => {
  if (
    (supportedLanguages as ReadonlyArray<string>).includes(navigator.language)
  ) {
    return navigator.language as SupportedLanguage;
  }
  return "en";
};

use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources as Resource,
    lng: getLocale(),
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
    returnEmptyString: false, // instead fallback to en
  });

export default i18next;
