import { styled, css } from "styled-components";
import { calculatedFontScaleRem } from "../styles/mixins";

type BodyTextVariant = "primary" | "secondary";

type BodyTextProps = {
  variant?: BodyTextVariant;
  noMargin?: boolean;
  fontSize?: number;
};

const BodyTextStyle = css`
  line-height: 1.5;
  white-space: pre-wrap;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.font.body};
  margin: 0 0 ${({ theme }) => theme.margin.m};
`;

export const BodyText = styled.p<BodyTextProps>`
  ${({ variant, theme }) =>
    `color: ${theme.color.text[variant ?? "secondary"]};`}
  ${BodyTextStyle};
  margin: 0 0 ${({ theme, noMargin }) => (noMargin ? "0" : theme.margin.m)};
  ${({ fontSize }) => calculatedFontScaleRem(fontSize ?? 1)}
`;
