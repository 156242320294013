import { useEffect } from "react";

interface Props {
  key: string;
  onKeyPressed: () => void;
}

export const useKeyboardHandler = ({ key, onKeyPressed }: Props) => {
  useEffect(() => {
    const keyDownHandler = (e: globalThis.KeyboardEvent) => {
      if (e.key === key) {
        e.preventDefault();
        onKeyPressed();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [key, onKeyPressed]);
};
