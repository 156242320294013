import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClaimState } from "./types";
import { useKeyboardHandler } from "./hooks";
import { claimCode } from "./api";
import {
  CodeInput,
  CodeInputWrapper,
  CodeInputsWrapper,
  ErrorSymbol,
} from "./styles/styledComponents";
import { Headline } from "./styledElements/Headline";
import { BodyText } from "./styledElements/BodyText";
import { MetaText } from "./styledElements/MetaText";
import errorSymbolSVG from "./assets/svgs/error_symbol.svg";

type ActivationCodeInputProps = {
  setMeetingClaim: (value: ClaimState) => void;
};

const findLastNonEmptyStringAndReplace = (array: string[]) => {
  const newArray = [...array];

  for (let i = newArray.length - 1; i >= 0; i--) {
    if (newArray[i].trim() !== "") {
      newArray[i] = "";
      return { value: newArray, position: i };
    }
  }
  return { value: newArray, position: -1 };
};

const ActivationCodeInput: React.FC<ActivationCodeInputProps> = ({
  setMeetingClaim,
}) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]); // Assuming a 6-character code
  const itemsRef = useRef<(HTMLInputElement | null)[]>([]);
  const [error, setError] = useState({ show: false, message: "" });
  const { t } = useTranslation();
  const lastIndex = code.length - 1;

  useKeyboardHandler({
    key: "Backspace",
    onKeyPressed: () => {
      const { value, position } = findLastNonEmptyStringAndReplace(code);
      setCode(value);
      itemsRef.current[position]?.focus();
    },
  });
  const updateCode = (index: number, value: string) => {
    const newCode = [...code];
    const uppercasedValue = value.toUpperCase();
    newCode[index] = uppercasedValue;
    setCode(newCode);
  };

  // todo debounce this
  useEffect(() => {
    if (code.join("").length !== 6) {
      setError({ show: false, message: "" });
      return;
    }
    const redirectToWaitroom = async () => {
      const meetingCode = code.join("").toUpperCase();
      const meetingClaimResult = await claimCode(meetingCode);
      if (meetingClaimResult.success) {
        setMeetingClaim({
          meetingCode,
          meetingStartsAt: meetingClaimResult.data.meetingStartsAt,
          slotId: meetingClaimResult.data.slotId,
        });
      } else {
        setError({ show: true, message: meetingClaimResult.error });
      }
    };
    redirectToWaitroom();
  }, [code, setMeetingClaim]);
  return (
    <>
      <Headline size="m">{t("pages.claim.enterCode")}</Headline>
      <CodeInputsWrapper>
        {code.map((_, index) => (
          <CodeInputWrapper
            key={`div-${index}`}
            $dash={index === Math.floor(lastIndex / 2)} // Add a dash in the middle
          >
            <CodeInput
              key={index}
              showError={error.show && index === lastIndex}
              type="text"
              value={code[index]}
              onChange={(e) => {
                const { value } = e.target;
                if (!/^[a-zA-Z0-9]+$/.test(value)) return;

                updateCode(index, value);
                itemsRef.current[index + 1]?.focus();
              }}
              maxLength={1}
              className="activation-code-box"
              ref={(ref) => {
                itemsRef.current[index] = ref;
              }}
            />
          </CodeInputWrapper>
        ))}
      </CodeInputsWrapper>
      {error.show && (
        <>
          <MetaText variant="error" style={{ display: "flex" }}>
            <ErrorSymbol src={errorSymbolSVG} />
            &nbsp;{error.message}
          </MetaText>
          <br />
        </>
      )}
      <BodyText variant="secondary" fontSize={0.5}>
        {t("pages.claim.cantFindCodeHelp")}
      </BodyText>
    </>
  );
};

export default ActivationCodeInput;
