import { createGlobalStyle } from "styled-components";
import { calculatedFontScaleRem, htmlFontSizes } from "./mixins";

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${({ theme }) => theme.color.primary.light};
    color: ${({ theme }) => theme.color.text.primary};
  }
  ::-moz-selection {
    background: ${({ theme }) => theme.color.primary.light};
    color: ${({ theme }) => theme.color.text.primary};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    ${htmlFontSizes()}
  }

  body {
    ${calculatedFontScaleRem(1)};
    margin: 0;
    padding: 0;
    display: block;
    color: ${({ theme }) => theme.color.text.primary};
    line-height: 1.5;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-family: ${({ theme }) => theme.font.body};
    font-feature-settings: "cv01", "cv11";
  }

  main {
    overflow: auto;
  }

  img, 
  picture, 
  video, 
  canvas, 
  svg {
    display: block;
    max-width: 100%;
  }

  input, 
  button, 
  textarea, 
  select {
    font: inherit;
  }

  p, 
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    overflow-wrap: break-word;
  }
  b {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  i {
    font-style: italic;
  }
`;

export { GlobalStyle };
