export const fluidFontSize = (
  minFs: number,
  maxFs: number,
  minVw: number,
  maxVw: number,
) => {
  return `calc(${minFs}px + (${maxFs - minFs}) * ((100vw - ${minVw}px) / (${
    maxVw - minVw
  })))`;
};

export const fluidFontScaleRem = (
  minFs: number,
  maxFs: number,
  minVw: number,
  maxVw: number,
) => {
  return `calc(${minFs}rem + (((100vw - ${minVw}rem) / (${maxVw - minVw})) * (${
    maxFs - minFs
  })))`;
};

export const px2rem = (value: number, base: number) => {
  return value / base;
};

export const colWidth = (cols: number, max: number) => {
  return `${(cols / max) * 100}%`;
};
