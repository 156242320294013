import { useState } from "react";
import { ClaimState } from "./types";
import ActivationCodeInput from "./ActivationCodeInput";
import WaitingRoom from "./WaitingRoom";
import lockSVG from "./assets/svgs/illustration_lock.svg";
import personsSVG from "./assets/svgs/illustration_persons.svg";
import logoSVG from "./assets/svgs/mindler.svg";
import { Logo, BrandingImage } from "./styles/styledComponents";
import {
  MainGrid,
  MainGridBranding,
  MainGridContent,
} from "./styledElements/MainGrid";

export const App = () => {
  const [meetingClaim, setMeetingClaim] = useState<ClaimState>();

  return (
    <MainGrid>
      <MainGridContent>
        <Logo src={logoSVG} alt="Logo" />
        {meetingClaim ? (
          <WaitingRoom meetingClaim={meetingClaim} />
        ) : (
          <ActivationCodeInput setMeetingClaim={setMeetingClaim} />
        )}
      </MainGridContent>
      <MainGridBranding>
        <BrandingImage
          src={meetingClaim ? personsSVG : lockSVG}
          alt={
            meetingClaim
              ? "Two persons greeting each other"
              : "Person waving inside a big keyhole"
          }
        />
      </MainGridBranding>
    </MainGrid>
  );
};
