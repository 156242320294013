import { styled, css } from "styled-components";
import { calculatedFontScaleRem } from "../styles/mixins";

type HeadlineProps = {
  serif?: boolean;
  noMargin?: boolean;
  size?: "s" | "m" | "l" | "xl";
};

const HeadlineStyle = css<HeadlineProps>`
  color: ${({ theme }) => theme.color.text.primary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme, serif }) =>
    serif ? theme.font.serif : theme.font.display};
  letter-spacing: ${({ theme, serif }) =>
    serif ? "auto" : theme.displayLetterSpacing};

  margin: 0 0 ${({ noMargin }) => (noMargin ? "0" : "0.5em")};
  ${({ size }) =>
    size === "s" &&
    css`
      ${calculatedFontScaleRem(2.625)};
      line-height: 1.25;
    `}
  ${({ size }) =>
    (size === "m" || size === undefined) &&
    css`
      ${calculatedFontScaleRem(4)};
      line-height: 1.2;
    `}
  ${({ size }) =>
    size === "l" &&
    css`
      ${calculatedFontScaleRem(5)};
      line-height: 1.1;
    `}
  ${({ size }) =>
    size === "xl" &&
    css`
      ${calculatedFontScaleRem(5.5)};
      line-height: 1.1;
    `}
`;

const Headline = styled.h2<HeadlineProps>`
  ${HeadlineStyle}
`;

export { Headline, HeadlineStyle };
export type { HeadlineProps };
