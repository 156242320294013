import { styled, css } from "styled-components";
import { calculatedFontScaleRem } from "../styles/mixins";

type MetaTextVariant = "primary" | "secondary" | "error";

type MetaTextProps = {
  variant?: MetaTextVariant;
};
export const MetaTextStyle = css`
  ${calculatedFontScaleRem(0)};
  margin: 0 0 ${({ theme }) => theme.margin.xs};
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.font.body};
`;

export const MetaText = styled.p<MetaTextProps>`
  ${MetaTextStyle};
  ${({ variant, theme }) =>
    `color: ${theme.color.text[variant ?? "secondary"]};`}
`;
