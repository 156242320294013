import { styled, css } from "styled-components";
import { calculatedFontScaleRem } from "./mixins";

export const BrandingImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: auto;
  transform: translate(-50%, -50%);
`;

export const Logo = styled.img`
  color: ${({ theme }) => theme.color.text.primary};
  width: 12rem;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CodeInputsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQuery.s} {
    gap: 1rem;
  }
`;

type CodeInputWrapperProps = {
  $dash?: boolean;
};

export const CodeInputWrapper = styled.div<CodeInputWrapperProps>`
  font-family: "Space Mono", monospace;
  ${calculatedFontScaleRem(4)};
  width: auto;

  ${({ theme, $dash }) =>
    $dash &&
    css`
      position: relative;
      padding-right: 1.5rem;
      ${theme.mediaQuery.s} {
        padding-right: 2rem;
      }
      &:after {
        content: "";
        display: block;
        height: 0.166667rem;
        width: 1rem;
        background: ${theme.color.text.primary};
        margin: 0;
        position: absolute;
        top: 50%;
        right: -0.125rem;

        ${theme.mediaQuery.s} {
          height: 0.25rem;
          width: 1.5rem;
          right: -0.25rem;
        }
      }
    `}
`;

export const CodeInput = styled.input<{ showError: boolean }>`
  font-family: "Inter";
  ${calculatedFontScaleRem(4)};
  text-align: center;
  width: 1.75em;
  height: 2em;
  padding: 0.5rem;
  border: 0.125rem solid
    ${({ theme, showError }) =>
      showError ? theme.color.border.error : theme.color.passive.medium};
  background: ${({ theme, showError }) =>
    showError ? theme.color.background.error : theme.color.background.primary};
  border-radius: 0.5rem;
  outline: none;
  transition: border-color 300ms, background 300ms;

  &:focus {
    border: 0.125rem solid
      ${({ theme, showError }) =>
        showError ? theme.color.border.error : theme.color.primary.regular};
    background: ${({ theme, showError }) =>
      showError ? theme.color.background.error : theme.color.primary.light};
    transition: border-color 50ms, background 50ms;
  }
`;

export const TimeLeftText = styled.p`
  font-family: "Inter";
  ${calculatedFontScaleRem(6)};
  margin: 0 0 2rem;
  line-height: 1em;
`;

export const ErrorSymbol = styled.img`
  display: inline-block;
`;
