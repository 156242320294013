import da from "./translations/da.json";
import en_GB from "./translations/en_GB.json";
import en from "./translations/en.json";
import nl from "./translations/nl.json";
import sv from "./translations/sv.json";
import { Translation } from "./translation";

export const supportedLanguages = ["da", "en-GB", "en", "nl", "sv"] as const;
export type SupportedLanguage = (typeof supportedLanguages)[number];

export const resources: Record<
  SupportedLanguage,
  { translation: Translation }
> = {
  da: {
    translation: { ...da },
  },
  "en-GB": {
    translation: { ...en_GB },
  },
  en: {
    translation: { ...en },
  },
  nl: {
    translation: { ...nl },
  },
  sv: {
    translation: { ...sv },
  },
};
