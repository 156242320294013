import { styled } from "styled-components";

export const MainGrid = styled.div`
  gap: 5rem;
  display: grid;
  grid-template-columns: 0 repeat(12, 1fr) 0;
  height: 100vh;
`;

export const MainGridContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 5rem;

  grid-column: 2 / 14;
  ${({ theme }) => theme.mediaQuery.xs} {
    grid-column: 2 / 9;
  }
  ${({ theme }) => theme.mediaQuery.s} {
    grid-column: 2 / 10;
  }
  ${({ theme }) => theme.mediaQuery.m} {
    grid-column: 2 / 9;
  }
  ${({ theme }) => theme.mediaQuery.l} {
    grid-column: 2 / 8;
  }
  position: relative;
`;

export const MainGridBranding = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQuery.s} {
    display: block;
    grid-column: 11 / 15;
  }
  ${({ theme }) => theme.mediaQuery.m} {
    grid-column: 10 / 15;
  }
  ${({ theme }) => theme.mediaQuery.l} {
    grid-column: 9 / 15;
  }
  position: relative;
  background: ${({ theme }) => theme.color.primary.regular};
`;
