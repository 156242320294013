import { css } from "styled-components";
import { fluidFontScaleRem, fluidFontSize } from "./functions";
import { mindler2022theme as mt } from "./mindler2022theme";

export const calculatedFontScaleRem = (multiple: number) => css`
  font-size: ${mt.fontSize.scaleMobile ** multiple}rem;

  @media all and (min-width: ${mt.breakpoint.xs}px) {
    font-size: ${fluidFontScaleRem(
      mt.fontSize.scaleMobile ** multiple,
      mt.fontSize.scaleDesktop ** multiple,
      mt.breakpoint.xsRem,
      mt.breakpoint.lRem,
    )};
  }

  @media all and (min-width: ${mt.breakpoint.l}px) {
    font-size: ${mt.fontSize.scaleDesktop ** multiple}rem;
  }
`;

export const htmlFontSizes = () => css`
  font-size: ${mt.fontSize.xxs}px;
  font-size: ${(mt.fontSize.xxs / mt.breakpoint.xs) * 100}vw;

  @media all and (min-width: ${mt.breakpoint.xxs}px) {
    font-size: ${mt.fontSize.xs}px;
  }

  @media all and (min-width: ${mt.breakpoint.xs}px) {
    font-size: ${mt.fontSize.xs}px;
    font-size: ${fluidFontSize(
      mt.fontSize.xs,
      mt.fontSize.l,
      mt.breakpoint.xs,
      mt.breakpoint.l,
    )};
  }

  @media all and (min-width: ${mt.breakpoint.s}px) {
    font-size: ${mt.fontSize.s}px;
    font-size: ${fluidFontSize(
      mt.fontSize.xs,
      mt.fontSize.l,
      mt.breakpoint.xs,
      mt.breakpoint.l,
    )};
  }

  @media all and (min-width: ${mt.breakpoint.m}px) {
    font-size: ${mt.fontSize.m}px;
    font-size: ${fluidFontSize(
      mt.fontSize.xs,
      mt.fontSize.l,
      mt.breakpoint.xs,
      mt.breakpoint.l,
    )};
  }

  @media all and (min-width: ${mt.breakpoint.l}px) {
    font-size: ${mt.fontSize.l}px;
    font-size: ${fluidFontSize(
      mt.fontSize.xs,
      mt.fontSize.l,
      mt.breakpoint.xs,
      mt.breakpoint.l,
    )};
  }

  @media all and (min-width: ${mt.breakpoint.xl}px) {
    font-size: ${mt.fontSize.xl}px;
    font-size: 0.789546638874414vw;
  }
`;
