import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ClaimState } from "./types";
import { TimeLeftText } from "./styles/styledComponents";
import { Button } from "./styledElements/Button";
import { Headline } from "./styledElements/Headline";
import { BodyText } from "./styledElements/BodyText";

interface TimeLeft {
  minutes: number;
  seconds: number;
  meetingIsReady: boolean;
}

type WaitingRoomProps = {
  meetingClaim: ClaimState;
};

const WaitingRoom: React.FC<WaitingRoomProps> = ({ meetingClaim }) => {
  const calculateTimeLeft = useCallback((): TimeLeft => {
    const difference = meetingClaim.meetingStartsAt - Date.now();
    let timeLeft = {
      minutes: 0,
      seconds: 0,
      meetingIsReady: !!(difference <= 0),
    };

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        meetingIsReady: !!(difference <= 0),
      };
    }

    return timeLeft;
  }, [meetingClaim.meetingStartsAt]);

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timerTimeout = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timerTimeout);
  }, [timeLeft, calculateTimeLeft]);

  const formatTime = (time: number): string => time.toString().padStart(2, "0");

  const handleJoinCall = () => {
    if (timeLeft.seconds > 0) return;
    const videoUrl = import.meta.env.VITE_VIDEO_STREAM_URL;
    const urlToMeeting = `${videoUrl}?slotId=${meetingClaim.slotId}&videoCode=${meetingClaim.meetingCode}`;
    window.location.href = urlToMeeting;
  };

  const { t } = useTranslation();

  return (
    <>
      <Headline size="m">
        {timeLeft.meetingIsReady
          ? t("pages.waitingRoom.meetingReady")
          : t("pages.waitingRoom.meetingWillStartIn")}
      </Headline>
      {!timeLeft.meetingIsReady && (
        <TimeLeftText>
          {formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
        </TimeLeftText>
      )}
      <BodyText>
        <Button
          inline
          disabled={!timeLeft.meetingIsReady}
          onClick={handleJoinCall}
        >
          {t("pages.waitingRoom.joinMeetingLabel")}
        </Button>
      </BodyText>
    </>
  );
};

export default WaitingRoom;
