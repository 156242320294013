import { createClient, RouterTypes } from "@mindlercare/videocall-auth-client";
import i18n from "./internationalization/i18n";

type ClaimCodeError = { success: false; error: string };

type ClaimCodeSuccess = {
  success: true;
  data: RouterTypes["claimCode"]["output"];
};

type TRPCClientError = {
  cause: Error | undefined;
  message: string;
};

export type ClaimResponse = RouterTypes["claimCode"]["output"];
type ClaimCodeResult = ClaimCodeSuccess | ClaimCodeError;

const errorMap: Record<string, string> = {
  ALREADY_CLAIMED: i18n.t("pages.claim.errors.codeAlreadyUsed"),
  NOT_FOUND: i18n.t("pages.claim.errors.codeDoesNotExistOrExpired"),
};
const getErrorMessage = (message: string) => {
  return errorMap[message] || i18n.t("pages.claim.errors.otherError");
};

export const claimCode = async (code: string): Promise<ClaimCodeResult> => {
  const videoCallClient = createClient({
    apiUrl: import.meta.env.VITE_API_URL,
  });
  try {
    const meetingClaimResult = await videoCallClient.claimCode.mutate({
      code,
    });
    return { success: true, data: meetingClaimResult };
  } catch (error: unknown) {
    if (error instanceof Error && error.name === "TRPCClientError") {
      const trpcError = error as unknown as TRPCClientError;
      return { success: false, error: getErrorMessage(trpcError.message) };
    }
    return { success: false, error: i18n.t("pages.claim.errors.otherError") };
  }
};
