import { styled, css } from "styled-components";
import { calculatedFontScaleRem } from "../styles/mixins";

type ButtonVariant = "Primary" | "Outlined" | "Black";

type ButtonSize = "s" | "m";

export type ButtonProps = {
  wide?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  inline?: boolean;
};

export const ButtonStyle = css<ButtonProps>`
  ${calculatedFontScaleRem(1)};
  line-height: 1.25;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.font.body};
  box-sizing: border-box;
  display: inline-block;

  ${({ size }) => size === "s" && "padding: 0.5em 1em;"}
  ${({ size }) =>
    (size === "m" || size === undefined) && "padding: 0.65em 1.25em;"}
  border-radius: 2em;
  text-align: center;
  text-decoration: none;
  width: ${({ wide }) => (wide ? "100%" : "auto")};
  border: none;
  appearance: none;
  cursor: pointer;
  transition: color ${({ theme }) => theme.transitionTime}ms
      ${({ theme }) => theme.ease.out},
    background ${({ theme }) => theme.transitionTime}ms
      ${({ theme }) => theme.ease.out},
    border-color ${({ theme }) => theme.transitionTime}ms
      ${({ theme }) => theme.ease.out};

  ${({ variant, theme }) =>
    (variant === "Primary" || variant === undefined) &&
    css`
      background: ${theme.color.primary.regular};
      border: 1px solid ${theme.color.primary.regular};
      color: ${theme.color.text.primary};

      &:hover,
      &:active {
        background: ${theme.color.primary.light};
        border-color: ${theme.color.primary.light};
        color: ${theme.color.text.primary};
      }
    `}

  ${({ variant, theme }) =>
    variant === "Outlined" &&
    css`
      background: transparent;
      border: 1px solid ${theme.color.text.primary};
      color: ${theme.color.text.primary};

      &:hover,
      &:active {
        background: ${theme.color.text.primary};
        border: 1px solid ${theme.color.text.primary};
        color: ${theme.color.background.primary};
      }
    `}

  ${({ variant, theme }) =>
    variant === "Black" &&
    css`
      background: ${theme.color.text.primary};
      border: 1px solid ${theme.color.text.primary};
      color: ${theme.color.background.primary};

      &:hover,
      &:active {
        background: ${theme.color.background.primary};
        border: 1px solid ${theme.color.text.primary};
        color: ${theme.color.text.primary};
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background: ${theme.color.primary.regular};
      border: 1px solid ${theme.color.primary.regular};
      color: ${theme.color.text.primary};
      opacity: 0.3;
      cursor: default;
      &:hover,
      &:active {
        background: ${theme.color.primary.regular};
        border: 1px solid ${theme.color.primary.regular};
        color: ${theme.color.text.primary};
      }
    `}

  ${({ wide, theme }) =>
    wide &&
    css`
      text-align: center;

      ${theme.mediaQuery.m} {
        width: auto;
        min-width: 20rem;
        max-width: 100%;
      }
    `}

  ${({ inline }) =>
    inline &&
    css`
      margin: 0.25rem 0.5rem 0.25rem 0;
    `}
`;
export const Button = styled.button<ButtonProps>`
  ${ButtonStyle}
`;
