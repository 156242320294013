import { createGlobalStyle } from "styled-components";

import InterRegularWoff2 from "../assets/fonts/inter/Inter-Regular.woff2";
import InterItalicWoff2 from "../assets/fonts/inter/Inter-Italic.woff2";

import InterBoldWoff2 from "../assets/fonts/inter/Inter-Bold.woff2";
import InterBoldItalicWoff2 from "../assets/fonts/inter/Inter-BoldItalic.woff2";

import InterDisplayRegularWoff2 from "../assets/fonts/inter/InterDisplay-Regular.woff2";
import InterDisplayItalicWoff2 from "../assets/fonts/inter/InterDisplay-Italic.woff2";

import InterDisplayMediumWoff2 from "../assets/fonts/inter/InterDisplay-Medium.woff2";
import InterDisplayMediumItalicWoff2 from "../assets/fonts/inter/InterDisplay-MediumItalic.woff2";

import SpaceMonoTTF from "../assets/fonts/spaceMono/SpaceMono-Regular.ttf";

const GlobalFonts = createGlobalStyle`
  @font-face { font-family: "Inter"; font-style: normal; font-weight: 400; font-display: swap; src: local('☺'), url(${InterRegularWoff2}) format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 400; font-display: swap; src: local('☺'), url(${InterItalicWoff2}) format("woff2"); }

  @font-face { font-family: "Inter"; font-style: normal; font-weight: 700; font-display: swap; src: local('☺'), url(${InterBoldWoff2}) format("woff2"); }
  @font-face { font-family: "Inter"; font-style: italic; font-weight: 700; font-display: swap; src: local('☺'), url(${InterBoldItalicWoff2}) format("woff2"); }

  @font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 400; font-display: swap; src: local('☺'), url(${InterDisplayRegularWoff2}) format("woff2"); }
  @font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 400; font-display: swap; src: local('☺'), url(${InterDisplayItalicWoff2}) format("woff2"); }

  @font-face { font-family: "InterDisplay"; font-style: normal; font-weight: 700; font-display: swap; src: local('☺'), url(${InterDisplayMediumWoff2}) format("woff2"); }
  @font-face { font-family: "InterDisplay"; font-style: italic; font-weight: 700; font-display: swap; src: local('☺'), url(${InterDisplayMediumItalicWoff2}) format("woff2"); }

  @font-face { font-family: "SpaceMono"; font-style: normal; font-weight: 400; font-display: swap; src: local('☺'), url(${SpaceMonoTTF}) format("ttf"); }
`;

export { GlobalFonts };
